import React, { useEffect } from 'react'
import { Columns, Hero, Container } from 'react-bulma-components'

// Components
import Layout from '../components/layout'
import SiteNav from '../components/navbar'
import '../styles/app.sass'

function NotFoundPage() {
  useEffect(() => {
    const el = document.getElementById('lnk')
    el.setAttribute('href', `/blog${window ? window.location.pathname : ''}`)
  }, [])

  return (
    <Layout>
      <SiteNav fullLogo="true" />
      <Hero>
        <Hero.Body>
          <Container className="content">
            <Columns>
              <Columns.Column className="is-offset-1 is-8">
                <h1 className="is-size-1 has-text-danger">
                  Oh no!
                  <sup>
                    <code className="is-size-6">404</code>
                  </sup>
                </h1>
                <h2>Your page has moved :(</h2>
                <p>
                  <a
                    className="button is-leafy is-primary"
                    id="lnk"
                    href="/blog"
                  >
                    Try this one?
                  </a>
                </p>
                <p>
                  <br />
                  Or have a <a href="/">quick browse around.</a>
                </p>
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
      </Hero>
    </Layout>
  )
}

// class NotFoundPage extends React.Component {
//   componentDidMount() {
//     const el = document.getElementById('lnk')
//     el.setAttribute('href', `/blog${window ? window.location.pathname : ''}`)
//   }

//   render() {
//     return (
//       <Layout>
//         <SiteNav fullLogo="true" />
//         <Hero>
//           <Hero.Body>
//             <Container className="content">
//               <Columns>
//                 <Columns.Column className="is-offset-1 is-8">
//                   <h1 className="is-size-1 has-text-danger">
//                     Oh no!
//                     <sup>
//                       <code className="is-size-6">404</code>
//                     </sup>
//                   </h1>
//                   <h2>Your page has moved :(</h2>
//                   <p>
//                     <a
//                       className="button is-leafy is-primary"
//                       id="lnk"
//                       href="/blog"
//                     >
//                       Try this one?
//                     </a>
//                   </p>
//                   <p>
//                     <br />
//                     Or have a <a href="/">quick browse around.</a>
//                   </p>
//                 </Columns.Column>
//               </Columns>
//             </Container>
//           </Hero.Body>
//         </Hero>
//       </Layout>
//     )
//   }
// }

export default NotFoundPage
